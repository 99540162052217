import React, { useContext, useState } from "react";
import "./CartItems.css";
import cross_icon from "../Assets/cart_cross_icon.png";
import { ShopContext } from "../../Context/ShopContext";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios"; // Ensure axios is imported

const CartItems = () => {
  const { products, cartItems, removeFromCart, getTotalCartAmount, clearCart } =
    useContext(ShopContext);
  const [paymentStatus, setPaymentStatus] = useState("");

  const saveShippingDetails = async (shippingDetails) => {
    try {
      const response = await axios.post(
        "https://api.vinotie.com/api/save-order",
        {
          shippingDetails,
          products: cartItems, // Make sure cartItems is correctly referenced
        }
      );
      console.log("Order saved:", response.data);
    } catch (error) {
      console.error(
        "Error saving order details:",
        error.response ? error.response.data : error
      );
    }
  };

  // PayPal SDK script configuration
  const initialOptions = {
    "client-id":
      "AcutV2bWwAwIGUrky5EiyG06jCaZBdBXl5DuJEn_4VK-1SriqDgWRT6oafWFreQ1lhIZRLdziy_axnFK",
    currency: "USD",
    intent: "capture",
    locale: "en_US", // Add this line to set the locale to English (United States)
  };

  const handlePaymentSuccess = (details) => {
    console.log("Transaction completed by", details.payer.name.given_name);
    console.log("Shipping details:", details.purchase_units[0].shipping); // Logging shipping details
    clearCart(); // Assuming you have a method to clear the cart in your context
    setPaymentStatus(
      "Your payment was successful. Thank you for your purchase!"
    );
    // Accessing shipping details
    const shippingDetails = details.purchase_units[0].shipping;
    console.log("Shipping Address:", shippingDetails.address);
    console.log("Full Name:", shippingDetails.name.full_name);

    // Store this information or perform actions like sending it to your server
    // For example, you can send this to your server for processing
    saveShippingDetails(shippingDetails);
  };

  const handlePaymentError = (error) => {
    console.error("Payment Error: ", error);
    setPaymentStatus(
      "There was a problem processing your payment. Please try again."
    );
  };

  return (
    <div className="cartitems">
      <div className="cartitems-format-main">
        <p>Products</p>
        <p>Title</p>
        <p>Price</p>
        <p>Quantity</p>
        <p>Total</p>
        <p>Remove</p>
      </div>
      <hr />
      {products.map((product) => {
        if (cartItems[product.id] > 0) {
          return (
            <div key={product.id}>
              <div className="cartitems-format-main cartitems-format">
                <img
                  className="cartitems-product-icon"
                  src={product.images[0]}
                  alt={product.name}
                />
                <p>{product.name}</p>
                <p>${product.new_price}</p>
                <button className="cartitems-quantity">
                  {cartItems[product.id]}
                </button>
                <p>${product.new_price * cartItems[product.id]}</p>
                <img
                  onClick={() => removeFromCart(product.id)}
                  className="cartitems-remove-icon"
                  src={cross_icon}
                  alt="Remove"
                />
              </div>
              <hr />
            </div>
          );
        }
        return null;
      })}

      <div className="cartitems-down">
        <div className="cartitems-total">
          <h1>Cart Totals</h1>
          <div>
            <div className="cartitems-total-item">
              <p>Subtotal</p>
              <p>${getTotalCartAmount()}</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <p>Shipping Fee</p>
              <p>Free</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <h3>Total</h3>
              <h3>${getTotalCartAmount()}</h3>
            </div>
          </div>
          {paymentStatus && <p className="payment-status">{paymentStatus}</p>}
          <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        currency_code: "USD", // Assuming USD as your currency
                        value: getTotalCartAmount().toString(), // Dynamically set total based on cart
                        breakdown: {
                          // Providing a detailed breakdown of the transaction
                          item_total: {
                            currency_code: "USD",
                            value: getTotalCartAmount().toString(),
                          },
                        },
                      },
                      items: products
                        .filter((product) => cartItems[product.id] > 0)
                        .map((product) => ({
                          // Ensure only products with quantity > 0 are included
                          name: product.name,
                          sku: product.id.toString(), // Use product ID as SKU
                          unit_amount: {
                            currency_code: "USD",
                            value: product.new_price.toString(),
                          },
                          quantity: cartItems[product.id].toString(),
                        })),
                    },
                  ],
                  application_context: {
                    shipping_preference: "GET_FROM_FILE", // PayPal will ask the buyer for a shipping address
                  },
                });
              }}
              onApprove={(data, actions) => {
                return actions.order
                  .capture()
                  .then(handlePaymentSuccess)
                  .catch(handlePaymentError);
              }}
            />
          </PayPalScriptProvider>

        </div>
        <div className="cartitems-promocode">
          <p><span className="font-weight-bold">Delivery Disclaimer:</span>Please note that delivery times typically range from 7 to 10 business days within the United States. However, the duration may vary based on your specific location and the details of your order. We appreciate your patience and understanding as we work to ensure your order arrives as promptly as possible.</p>
          <p className="text-center mt-4">
            <span className="font-weight-bold">Contact Us:</span> For updates on your order status, please write to us at <a href="mailto:info@vinotie.com">info@vinotie.com</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CartItems;
