import React, { useEffect, useState } from "react";
import "./CSS/ShopCategory.css";
import Item from "../Components/Item/Item";
import { Link } from "react-router-dom";
import categoryBanner from "../Components/Assets/Images/Product banner.webp"; // Ensure this is your desired banner

const ShopCategory = (props) => {
  const [allproducts, setAllProducts] = useState([]);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitStatus, setSubmitStatus] = useState("");

  const fetchInfo = () => {
    fetch("https://api.vinotie.com/allproducts")
      .then((res) => res.json())
      .then((data) => setAllProducts(data));
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    fetch("https://api.vinotie.com/submit-inquiry", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, message }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setSubmitStatus("Inquiry submitted successfully.");
          setEmail("");
          setMessage("");
        } else {
          setSubmitStatus("Failed to submit inquiry.");
        }
      })
      .catch((error) => {
        console.error("Error submitting inquiry:", error);
        setSubmitStatus("Error submitting inquiry.");
      });
  };

  return (
    <div className="shopcategory">
      <img
        src={categoryBanner}
        className="shopcategory-banner"
        alt="Category Banner"
      />
      <div className="shopcategory-products">
        {allproducts.map((item, i) => {
          if (props.category === item.category) {
            return (
              <Item
                id={item.id}
                key={i}
                name={item.name}
                image={item.images[0]}
                new_price={item.new_price}
                old_price={item.old_price}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
      {props.category === "kid" && (
        <div className="shopcategory-description">
          <h1>Restaurant Wholesale Inquiries</h1>
          <p>
            We offer custom Vino Ties™️ for restaurants that align with your
            brand's aesthetics. Contact us to learn more.
          </p>
          <form onSubmit={handleSubmit}>
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
            <label htmlFor="message">Your Message</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Enter your message"
              required
            ></textarea>
            <button type="submit">Submit</button>

            <p className="text-center mt-4">
                        <span className="font-weight-bold">Contact Us:</span> For updates on your order status, please write to us at <a href="mailto:info@vinotie.com">info@vinotie.com</a>.
            </p>
          </form>
          {submitStatus && <p>{submitStatus}</p>}
        </div>
      )}
      <div className="shopcategory-loadmore">
        <Link to="/" style={{ textDecoration: "none" }}>
          Explore More
        </Link>
      </div>
    </div>
  );
};

export default ShopCategory;
