import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Offers.css";
import exclusive_image from "../Assets/Images/DALL·E 2024-05-19 14.26.31 - A realistic image of a wine bottle in a fancy restaurant, zoomed out to show more of the surrounding setting. The wine bottle has a neatly tied tie ar.webp";

const Offers = () => {
  const navigate = useNavigate(); // Create a navigate function instance

  // Function to handle button click
  const handleNavigate = () => {
    navigate("/restaurant"); // Assume "/kids" is your Restaurant Wholesale page route
  };

  return (
    <div className="offers">
      <div className="offers-left">
        <h1>Restaurant</h1>
        <h1>Wholesale</h1>
        
        <button onClick={handleNavigate}>CONTACT US</button> {/* Use the navigate function on click */}
      </div>
      <div className="offers-right">
        <img src={exclusive_image} alt="" />
      </div>
    </div>
  );
};

export default Offers;
