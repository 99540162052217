import React from 'react'
import './Footer.css'

import footer_logo from '../Assets/Images/Vino Ties Logo Circle.png'
import instagram_icon from '../Assets/instagram_icon.png'
import whatsapp_icon from '../Assets/whatsapp_icon.png'

const Footer = () => {
  return (
    <div className='footer'>
      <p>Tie your knot, forget about the drop! Marketing your every spill, dribble and drop!
</p>
      <div className="footer-logo">
        <img src={footer_logo} alt="" />
        
      </div>
      <div className="footer-social-icons">
      <div className="footer-icons-container">
    <a href="https://www.instagram.com/vino_tie?igsh=MXcyd2dsa2xoa29uNg%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
        <img src={instagram_icon} alt="Instagram" />
    </a>
</div>
        
        <div className="footer-icons-container">
    <a href="https://wa.me/13054507123" target="_blank" rel="noopener noreferrer">
        <img src={whatsapp_icon} alt="WhatsApp" />
    </a>
</div>
      </div>
      <div className="footer-copyright">
        <hr />
        <p>© 2024, Vino Ties™️</p>
      </div>
    </div>
  )
}

export default Footer
