import React, { useContext, useRef, useState } from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'
import logo from '../Assets/Images/Vino Ties Logo.png'
import cart_icon from '../Assets/cart_icon.png'
import person_icon from '../Assets/Images/Person Icon.png'
import { ShopContext } from '../../Context/ShopContext'
import menu_icon from '../Assets/Images/Vino Ties Logo Circle.png' 

const Navbar = () => {

  let [menu, setMenu] = useState("shop");
  const { getTotalCartItems } = useContext(ShopContext);

  const menuRef = useRef();

  const dropdown_toggle = (e) => {
    menuRef.current.classList.toggle('nav-menu-visible');
    e.target.classList.toggle('open');
  }

  return (
    <div className="nav">
      <div className="nav-left">
        <img
          onClick={dropdown_toggle}
          className="nav-dropdown"
          src={menu_icon}
          alt="menu"
        />
        <ul className="nav-menu-left">
          <li
            onClick={() => {
              setMenu("Home");
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              Home
            </Link>
            {menu === "home" ? <hr /> : <></>}
          </li>
          <li
            onClick={() => {
              setMenu("about");
            }}
          >
            <Link to="/about" style={{ textDecoration: "none" }}>
              About Us
            </Link>
            {menu === "about" ? <hr /> : <></>}
          </li>
        </ul>
      </div>
      <div className="nav-center">
        <Link
          to="/"
          onClick={() => {
            setMenu("shop");
          }}
          style={{ textDecoration: "none" }}
          className="nav-logo"
        >
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className="nav-right">
        <ul className="nav-menu-right">
          <li
            onClick={() => {
              setMenu("products");
            }}
          >
            <Link to="/products" style={{ textDecoration: "none" }}>
              Products
            </Link>
            {menu === "products" ? <hr /> : <></>}
          </li>
          <li
            onClick={() => {
              setMenu("restaurant");
            }}
          >
            <Link to="/restaurant" style={{ textDecoration: "none" }}>
              Restaurant Wholesale
            </Link>
            {menu === "restaurant" ? <hr /> : <></>}
          </li>
        </ul>
        <div className="nav-icons">
          <div className="nav-login">
            {localStorage.getItem("auth-token") ? (
              <img
                src={person_icon}
                alt="logout"
                onClick={() => {
                  localStorage.removeItem("auth-token");
                  window.location.replace("/");
                }}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <Link to="/login" style={{ textDecoration: "none" }}>
                <img src={person_icon} alt="login" />
              </Link>
            )}
          </div>
          <div className="nav-cart">
            <Link to="/cart">
              <img src={cart_icon} alt="cart" />
            </Link>
            <div className="nav-cart-count">{getTotalCartItems()}</div>
          </div>
        </div>
      </div>
      <ul ref={menuRef} className="nav-menu-mobile">
        <li
          onClick={() => {
            setMenu("Home");
          }}
        >
          <Link to="/" style={{ textDecoration: "none", fontWeight: "bold" }}>
            Home
          </Link>
          {menu === "home" ? <hr /> : <></>}
        </li>
        <li
          onClick={() => {
            setMenu("about");
          }}
        >
          <Link
            to="/about"
            style={{ textDecoration: "none", fontWeight: "bold" }}
          >
            About Us
          </Link>
          {menu === "about" ? <hr /> : <></>}
        </li>
        <li
          onClick={() => {
            setMenu("products");
          }}
        >
          <Link
            to="/products"
            style={{ textDecoration: "none", fontWeight: "bold" }}
          >
            Products
          </Link>
          {menu === "womens" ? <hr /> : <></>}
        </li>
        <li
          onClick={() => {
            setMenu("restaurant");
          }}
        >
          <Link
            to="/restaurant"
            style={{ textDecoration: "none", fontWeight: "bold" }}
          >
            Restaurant Wholesale
          </Link>
          {menu === "kids" ? <hr /> : <></>}
        </li>
      </ul>
    </div>
  );
}

export default Navbar
