import React from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import "./Hero.css";
import hero_image from "../Assets/Images/Glass and Bottle Tie White/AI Vino 2.jpeg";
import arrow_icon from "../Assets/arrow.png";

const Hero = () => {
  const navigate = useNavigate(); // Hook for navigation
  return (
    <div className="hero">
      <div className="hero-left">
        <h2>Tie your knot</h2>
        <h2>Forget about the drop!</h2>
        <div>
          <div className="hero-hand-icon"></div>
        </div>
        <div className="hero-latest-btn" onClick={() => navigate("/products")}>
          <div>View all Ties</div>
          <img src={arrow_icon} alt="" />
        </div>
      </div>
      <div className="hero-right">
        <img src={hero_image} alt="hero" />
      </div>
    </div>
  );
};

export default Hero;
