import React from 'react'
import './Breadcrums.css'
import arrow_icon from '../Assets/breadcrum_arrow.png'
import { Link } from 'react-router-dom'; // Import Link

const Breadcrums = (props) => {
  const { product } = props;

  if (!product) {
    return null; // Render nothing if product is undefined
  }

  return (
    <div className='breadcrumbs'>
    <Link to="/">Home</Link> 
    <img src={arrow_icon} alt="" />
    <Link to="/products">Shop</Link> 
    <img src={arrow_icon} alt="" /> 
    {product.name}
</div>
  )
}

export default Breadcrums
