import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./ProductDisplay.css";
import star_icon from "../Assets/star_icon.png";
import star_dull_icon from "../Assets/star_dull_icon.png";
import { ShopContext } from "../../Context/ShopContext";
import Breadcrums from "../Breadcrums/Breadcrums"; // Adjust the path as necessary

const ProductDisplay = () => {
  const { productId } = useParams(); // Use useParams to get the productId from the route parameters
  const { products, addToCart } = useContext(ShopContext);

  const [product, setProduct] = useState(null);

  useEffect(() => {
    const fetchedProduct = products.find(p => p.id === parseInt(productId));
    if (fetchedProduct) {
      setProduct(fetchedProduct);
    }
  }, [productId, products]);

  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (product && product.images.length > 0) {
      setSelectedImage(product.images[0]);
    }
  }, [product]);

  if (!product) {
    return <div>Loading...</div>; // Add a loading state while the product is being fetched
  }

  return (
    <div className="productdisplay">
      <Breadcrums product={product} /> {/* Pass product to Breadcrums */}
      <div className="productdisplay-content">
        <div className="productdisplay-left">
          <div className="productdisplay-img-list">
            {product.images.map((img, index) => (
              <img 
                key={index} 
                src={img} 
                alt="img" 
                onClick={() => setSelectedImage(img)} // Update selected image on click
                className={img === selectedImage ? "selected-thumbnail" : ""}
              />
            ))}
          </div>
          <div className="productdisplay-img">
            <img className="productdisplay-main-img" src={selectedImage} alt="img" /> {/* Display the selected image */}
          </div>
        </div>
        <div className="productdisplay-right">
          <h1>{product.name}</h1>
          <div className="productdisplay-right-stars">
            
          </div>
          <div className="productdisplay-right-prices">
            <div className="productdisplay-right-price-old">${product.old_price}</div>
            <div className="productdisplay-right-price-new">${product.new_price}</div>
          </div>
          <div className="productdisplay-right-description">
            {product.description || "No description provided."}  
          </div>
          <div>
            <button onClick={() => { addToCart(product.id) }}>ADD TO CART</button>
            <p className="productdisplay-right-category"><span>Category :</span> Original Vino Ties</p>
            <p className="productdisplay-right-category"><span>Tags :</span> Modern, Latest</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDisplay;
