import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './CSS/AboutUs.css';  // Make sure the import path is correct
import vinoTiesImage from '../Components/Assets/Images/Never Pour without it.jpeg';  // Adjust the path as necessary


const AboutUs = () => {
    return (
        <Container className="my-5">
            <Row className="justify-content-center">
                <Col md={8}>
                    <h1 className="mb-4 text-center">About Vino Ties™️</h1>
                    <p>The creation and embodiment of Vino Ties™️ was conceived at a family dinner table in 2016. The birth and fruition of the concept came to pass in 2020 and realized in 2021.</p>
                    <p>Vino Ties™️ is an up and coming brand taking the wine and champagne world by storm. We are a physical wine tie that is 2 fold. We make sure no drop or dribble is left behind streaming down the wine bottle all while promoting your logo on the tie itself!</p>
                    <p>Whether you are a small company, big corporation, wedding or any religious celebration, there is no order too large or too small for Vino Ties™️. We use the highest quality material in our patented proprietary fabric. Our Vino Ties™️ dry quickly and are machine washable too!</p>
                    {/* Image added here */}
                    <img src={vinoTiesImage} alt="Vino Ties Product" className="my-3" style={{ width: '50%', height: '50%' }} />
                    <p className="font-weight-bold text-center">Remember the answer is clear: Tie your knot, forget about the drop!</p>
                    {/* Added a new paragraph for tracking information */}
            
                </Col>
            </Row>
        </Container>
    );
}

export default AboutUs;
